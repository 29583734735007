export default defineNuxtRouteMiddleware(async () => {
  const settings = useSettings();

  const diners = settings.value.customer.diners;

  if (diners.length === 0) {
    return navigateTo(
      {
        name: "diners-add",
      },
      { replace: true },
    );
  }
});
